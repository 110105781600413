export class GoogleTagManager {

  static load(
    tagManagerToken,
    tagManagerAuth,
    tagManagerEnv,
    gtmServerUrl,
    customerId,
    googleTagManageremailAddressSHA1,
    googleTagManageremailAddressSHA256,
    klaviyoExternalId
  ) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'customer_email_sha1': googleTagManageremailAddressSHA1,
      'customer_email_sha256': googleTagManageremailAddressSHA256,
      'customerId': customerId,
      'klaviyoExternalId': klaviyoExternalId,
    });

    if (tagManagerToken &&  tagManagerAuth && tagManagerEnv && gtmServerUrl) {
      /* eslint-disable */
      // Boilerplate GA install snippet
      (function(w, d, s, l, i) {
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
            j.src=`https://${gtmServerUrl}/gtm.js?id=`+i+dl+`&gtm_auth=${tagManagerAuth}&gtm_preview=${tagManagerEnv}&gtm_cookies_win=x`;
            f.parentNode.insertBefore(j,f);
      })(window, document, 'script', 'dataLayer', tagManagerToken);
      /* eslint-enable */
    }
  }

  static pageView(customData) {
    window.dataLayer.push({
      'cart_value': customData.googleTagManager.cartValue,
      'cart_items': customData.googleTagManager.cartItems
    });

    if (customData.fbAds) {
      window.dataLayer.push({
        'content_name': customData.fbAds.contentName,
        'content_type': customData.fbAds.contentType,
        'content_ids': customData.fbAds.contentIds,
        'num_items': customData.fbAds.numItems,
        'value': customData.fbAds.value,
        'currency': customData.fbAds.currency
      });
    }

    if (customData.page) {
      window.dataLayer.push({
        'event': 'category_view',
        'slug': customData.page.slug
      });
    }

    if (customData.googleTagManager && customData.googleTagManager.checkoutFlow) {
      window.dataLayer.push({
        'checkout_items': customData.googleTagManager.checkoutFlow.productData,
        'checkout_total': customData.googleTagManager.checkoutFlow.total,
        'checkout_items_tiktok': customData.googleTagManager.checkoutFlow.tikTokData,
        'itemNames': customData.googleTagManager.checkoutFlow.itemNames
      });
    }
  }

  static orderCompleted(customData) {
    window.dataLayer.push({
      'order_id': customData.actionData.id,
      'order_revenue': customData.actionData.revenue,
      'order_coupon': customData.actionData.coupon,
      'order_city': customData.actionData.city,
      'order_state': customData.actionData.state,
      'order_zip_code': customData.actionData.zip_code,
      'order_country': customData.actionData.country,
      'order_email_sha256': customData.actionData.email_sha256,
      'order_first_name_sha256': customData.actionData.first_name_sha256,
      'order_last_name_sha256': customData.actionData.last_name_sha256,
      'order_products': customData.productData,
      'order_total_discount': customData.actionData.total_discount,
      'order_tax': customData.actionData.tax,
      'order_shipping': customData.actionData.shipping,
      'order_products_tiktok': customData.tikTokData,
      'order_products_impact_radius': customData.impactRadiusData
    });
  }

  static productDetailViewed(customData) {
    window.dataLayer.push({
      'product_name': customData.productData.name,
      'product_category': customData.productData.category,
      'product_price': customData.productData.price,
      'product_data': [{
        'item_id': customData.productData.id,
        'item_name': customData.productData.name,
        'item_category': customData.productData.category,
        'item_variant': customData.productData.variant,
      }],
      'product_data_tiktok': [{
        'content_id': customData.productData.id,
        'content_name': customData.productData.name,
        'content_type': 'product',
        'price': customData.productData.price_tiktok,
      }]
    });
  }
}
